/*
|--------------------------------------------------------------------------
| Popup et sidepanel
|--------------------------------------------------------------------------
|
| Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
*/

import Overlay from '../../libraries/overlay.js'
import { OBSERVER } from '../main.js'
import { Accordions } from './accordions.js'
import { copyTextToClipboard } from './functions.js'

// Fonction gérant l'overlay Search
export const overlayDropdown = (root = document) => {
  let dropdown = new Overlay({
    name: 'dropdown',
    create: { close: false },
    click: { buttons: { trigger: '.js-trigger-overlay-dropdown', close: '.js-close-all-overlay, .js-close-overlay-dropdown, .overlay-dropdown__background', switch: '.js-toggle-overlay-search' }},
    options: {
      speed: 800,
      root: root
    }
  })

  dropdown.init()
}


// Fonction gérant l'overlay Search
export const overlaySearch = (root = document) => {
  let search = new Overlay({
    name: 'search',
    create: { close: false },
    click: { buttons: { toggle: '.js-toggle-overlay-search', switch: '.js-trigger-overlay-dropdown, .js-toggle-overlay-menu' }},
    animations: {
      addTransition: true,
      styles: [{ property: 'height', value: '100%', easing: 'easeInOutQuart' }]
    },
    events: {
      openComplete: true,
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root
    }
  })
  
  search.init()

  addEventListener('onOpenCompleteOverlaySearch', giveFocusToSearch)

  function giveFocusToSearch(){
    root.querySelector('.js-input-to-clear').focus()
  }
}


// Fonction gérant l'overlay de partage
export const overlayShare = (root = document) => {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  let share = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-open-overlay-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
      root: root
    }
  })

  share.init()

  $('#copyShareBtn').click( function() {
    var link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })

  $('.rubric__share').on('click', function(e) {
    window.history.pushState(null, null, '#' + e.currentTarget.id)
    //pour le partage par courriel
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Technoflex&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Technoflex&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })
}


// Fonction gérant l'overlay Search
export const overlayMenu = (root = document) => {
  let menu = new Overlay({
    name: 'menu',
    create: { close: false },
    click: { buttons: { close:'.js-close-overlay', toggle: '.js-toggle-overlay-menu', switch: '.js-toggle-overlay-search' }},
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root
    }
  })
  
  menu.init()

  new Accordions({ containerClass: 'js-accordions-menu', scrollToAccordion: false, root: root })
}
