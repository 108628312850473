/*
|--------------------------------------------------------------------------
| Fonctions
|--------------------------------------------------------------------------
|
| Les divers scripts du site.
*/
import $ from 'jquery'
import anime from 'animejs/lib/anime.es.js'
import { OBSERVER } from './../main.js'
import { getElementOffset,isVisible } from './helper.js'

// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
    root: options.root
  })
  
  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    root: options.root,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}

// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? options.root.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element, root: options.root }).top + options.offset })
  
  return animation.finished
}

window.scrollToBlock = scrollToBlock

// Ajouter des marges pour les <p> qui contiennent des boutons
export function adjustButtonsMargins(){
  $( '.dynamic p > a.btn, .dynamic p > a.btn--secondary' ).each(function() {
    $(this).parent().addClass('buttonMargin')
  })
}

// Ajouter un <span> dans les boutons pour l'animation
export function animButtons() {
  let buttons = $('.btn, .btn--secondary, .js-hover-btn')
  buttons.wrapInner('<span></span>');
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}

function fallbackCopyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}

export const printPageOnClick = (options = {}) => {
  options.root === undefined ? options.root  = document : options.root
  options.target === undefined ? options.target = '.js-print-page' : options.target
  
  OBSERVER.add({
    name: 'printPage',
    event: 'click',
    target: options.target,
    root: options.root,
    function: printPage
  })

  OBSERVER.on('printPage')
}
function printPage(){
  window.print()
}

export function selectProductOnClick(options = {}) {
  options.root === undefined ? options.root  = document : options.root
  options.target === undefined ? options.target = '.js-buy-online-select' : options.target
  options.button === undefined ? options.button = '.js-buy-online-button' : options.button

  const onChange = () => {
    let button = $(options.button)
    let link = $(options.target).find('option:selected').attr('data-url')
    button.attr('href', link)
  }

  OBSERVER.add({
    name: 'productSelected',
    event: 'change',
    target: options.target,
    root: options.root,
    function: onChange
  })

  OBSERVER.on('productSelected')
}


export const initBackToTop = (options = {}) => {
  options.root === undefined ? options.root  = document : options.root
  
  OBSERVER.add({
    name: 'manageBackToTop',
    event: 'scroll',
    root: options.root,
    function: manageBackToTop
  })

  manageBackToTop()

  OBSERVER.on('manageBackToTop')
}
function manageBackToTop(){

  let headerVisible = isVisible('header')
  let footerVisible = isVisible('.footer__bottom')

  if(!headerVisible && !footerVisible){
    document.querySelector('html').classList.add("show-back-to-top")
  }else{
    document.querySelector('html').classList.remove("show-back-to-top")
  }
  
}


// Fonction gèrant l'image à afficher dans le dropdown
export function displayImages(options = {}) {
  options.root === undefined ? options.root  = document : options.root
  //options.container === undefined ? options.container  = '.js-drop-product' : options.root

  let i, images, imagesLength
  let doc = options.root

  OBSERVER.add({
    name: 'displayImages',
    event: 'mouseenter',
    root: options.root,
    target: options.container + ' .js-dropdown-image',
    function: mouseenter
  })
  OBSERVER.on('displayImages')

  OBSERVER.add({
    name: 'displayImages',
    event: 'mouseleave',
    root: options.root,
    target: options.container + ' .js-dropdown-image',
    function: mouseleave
  })
  OBSERVER.on('displayImages')


  function mouseenter(e) {
    if(typeof e.target.dataset.image !== "undefined"){
      let imagePath = options.container +' img[src=\''+ e.target.dataset.image +'\']'
      doc.querySelector(imagePath).classList.add('active')
    }
  }

  function mouseleave(e) {
    images = doc.querySelectorAll(options.container + ' .overlay-dropdown__picture img')
    imagesLength = images.length
    // Retirer la classe active partout
    for (i=0; i<imagesLength; i++)
      images[i].classList.remove('active')

    doc.querySelector(options.container + ' .js-drop-image-default').classList.add('active')
  }
}

//Permet d'enlever les accents d'une string
export function slugifyProvider(provider) {
  let result
  result = provider.replace('é', 'e')
  result = result.replace('É', 'E')
  result = result.replace(/ /g,"-")
  result = result.toLowerCase()

  return result
}