import barba from '@barba/core'
import { scrollToBlock } from '../functions/functions.js'
import { delay } from '../functions/helper.js'


export const defaultTransition = (duration) => {

    const curtain = document.querySelector('.curtain')

    return {
        name: 'default-transition',

        beforeOnce: () => Promise.all([
            curtain.style.transitionProperty = 'none',
            barba.wrapper.classList.add('show-once-transition'),
            delay(1000) 
        ]),

        once: () => Promise.all([
            curtain.style.transitionProperty = '',
            barba.wrapper.classList.remove('show-once-transition'),
            delay(600)
        ]),

        leave: () => Promise.all([
            barba.wrapper.classList.add('show-default-transition'),
            scrollToBlock({ duration: duration } ),
            delay(600)
        ]),

        enter: () => Promise.all([
            barba.wrapper.classList.remove('show-default-transition'),
        ]),
    }
}