/*
|--------------------------------------------------------------------------
| Sliders
|--------------------------------------------------------------------------
|
| Création de sliders.
*/

export const slickBanner = (root = document) => {

  $(root).find('.js-slick-home-banner-image').slick({
    dots: false,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    asNavFor: '.js-slick-home-banner-text',
  })

  $(root).find('.js-slick-home-banner-text').slick({
    dots: true,
    arrows: false,
    appendDots: '.js-slick-home-banner-dots',
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    asNavFor: '.js-slick-home-banner-image',
  })

}


export const slickProducts = (root = document) => {

  $(root).find('.js-slick-products').slick({
    appendArrows: '.js-slick-products-arrows',
    prevArrow: '<div class="slick-prev"><svg role="img"><use xlink:href="/themes/core/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    nextArrow: '<div class="slick-next"><svg role="img"><use xlink:href="/themes/core/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    dots: false,
    arrows: true,
    slidesToShow: 2,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  })

}

export const slickProduct = (root = document) => {

  $(root).find('.js-slick-product').slick({
    dots: true,
    arrows: false,
    appendDots: '.js-slick-product-dots',
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
  })

}


export const slickSimilarProducts = (root = document) => {

  $(root).find('.js-slick-products').slick({
    appendArrows: '.js-slick-products-arrows',
    prevArrow: '<div class="slick-prev"><svg role="img"><use xlink:href="/themes/core/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    nextArrow: '<div class="slick-next"><svg role="img"><use xlink:href="/themes/core/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    dots: false,
    arrows: true,
    slidesToShow: 3,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  })

}


export const slickProductsCategory = (root = document) => {

  $(root).find('.js-slick-products-category').slick({
    appendArrows: '.js-slick-products-category-arrows',
    prevArrow: '<div class="slick-prev"><svg role="img"><use xlink:href="/themes/core/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    nextArrow: '<div class="slick-next"><svg role="img"><use xlink:href="/themes/core/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    dots: false,
    arrows: true,
    slidesToShow: 1,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 6000,
    pauseOnHover: false,
  })

}


export const slickTestimonials = (root = document) => {

  $(root).find('.js-slick-testimonials-pictures').slick({
    dots: false,
    arrows: false,
    slidesToShow: 1,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    asNavFor: '.js-slick-testimonials-texts',
  })

  $(root).find('.js-slick-testimonials-texts').slick({
    dots: false,
    arrows: true,
    appendArrows: '.js-slick-testimonials-arrows',
    prevArrow: '<div class="slick-prev"><svg role="img"><use xlink:href="/themes/core/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    nextArrow: '<div class="slick-next"><svg role="img"><use xlink:href="/themes/core/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    asNavFor: '.js-slick-testimonials-pictures',
  })

  let total = $(root).find('.js-slick-testimonials-texts .slick-slide:not(.slick-cloned)').length
  $(root).find('.js-slick-testimonials-total-slide').html(total)

  $(root).find('.js-slick-testimonials-texts').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $(root).find('.js-slick-testimonials-current-slide').html(nextSlide+1)
  })

}


// Fonction permettant de détruire un carousel Slick
export function destroySlick() {
  $('.slick-initialized').slick('unslick')
}