/*
|--------------------------------------------------------------------------
| Les vues
|--------------------------------------------------------------------------
|
| Attribution des scripts à éxécuter sur chaque page selon leurs vues
*/

// Importation ----------------------------------------------------------------------------------------------------
import Overlay from '../libraries/overlay.js'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { slickBanner, slickProducts, slickProduct, slickSimilarProducts, slickProductsCategory, slickTestimonials, destroySlick } from './functions/sliders.js'
import { forceBlankOnExterneAndPdfLinks, removeHoverOnMobile } from './functions/helper.js'
import { adjustButtonsMargins,animButtons, printPageOnClick, clickToScrollToBlock, selectProductOnClick, initBackToTop, displayImages, slugifyProvider } from './functions/functions.js'
import { fileUpload, select, textareaHeight, formContact, formEmploi, formBecomeMember, formSearch, clearInput, inputsAndTextareaLabel, formNewsletter } from './functions/form.js'
import { overlayDropdown, overlaySearch, overlayShare, overlayMenu } from './functions/overlays.js'
import { Accordions } from './functions/accordions.js'

window.slugifyProvider = slugifyProvider

// Initialisation sur toutes les pages
export class View {
  static init(next) {
    // global init
    SCROLLFIRE.init()

    forceBlankOnExterneAndPdfLinks()
    adjustButtonsMargins()
    animButtons()
    inputsAndTextareaLabel()
    
    overlayDropdown(next.container)
    overlaySearch(next.container)
    overlayShare(next.container)
    overlayMenu(next.container)
    
    removeHoverOnMobile()
    
    displayImages({ container: '.js-drop-products' })
    displayImages({ container: '.js-drop-choose-technoflex' })

    initBackToTop(next.container)
    clickToScrollToBlock({ selector: '.js-scroll-to-top', scrollTo: 'header', offset: 0 ,root: next.container })

    formSearch('#search-form','#submit-search')
  }

  static leave() {
    // global leave
    SCROLLFIRE.destroy()
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
  }
}

// Initialisation sur la page générique
export class Generic extends View {
  static initGeneric(next) {
    this.init(next)
    //inputsAndTextareaLabel(next.container)
    fileUpload(next.container)
    select()
    textareaHeight()
    slickTestimonials(next.container)
    slickSimilarProducts(next.container)
    slickProductsCategory(next.container)
    formBecomeMember()
    formNewsletter()

    new Accordions()
  }
  
  static leaveGeneric(current) {
    this.leave(current)
    destroySlick(current.container)
  }
}

// Initialisation sur la page générique
export class Home extends View {
  static initHome(next) {
    this.init(next)
    slickBanner(next.container)
    slickProducts(next.container)
    clickToScrollToBlock({ selector: '.js-scroll-to-products', scrollTo: '.js-products-section', offset: 0 ,root: next.container })
  }
  
  static leaveHome(current) {
    this.leave(current)
    destroySlick(current.container)
  }
}

// Initialisation sur la page de liste de produits
export class Products extends View {
  static initProducts(next) {
    this.init(next)
    select()
  }
  
  static leaveProducts(current) {
    this.leave(current)
  }
}

// Initialisation sur la page de liste de produits
export class ProductsCategory extends View {
  static initProductsCategory(next) {
    this.init(next)
    slickProduct(next.container)
    clickToScrollToBlock({ selector: '.js-scroll-to-description', scrollTo: '.js-description-section', offset: -50 ,root: next.container })
    clickToScrollToBlock({ selector: '.js-scroll-to-advantages', scrollTo: '.js-advantages-section', offset: -50 ,root: next.container })
    clickToScrollToBlock({ selector: '.js-scroll-to-products', scrollTo: '.js-products-section', offset: 0 ,root: next.container })
    slickSimilarProducts(next.container)
    slickProductsCategory(next.container)
  }
  
  static leaveProductsCategory(current) {
    this.leave(current)
    destroySlick(current.container)
  }
}

// Initialisation sur la page de détail de produit
export class Product extends View {
  static initProduct(next) {
    this.init(next)
    slickProduct(next.container)
    printPageOnClick()
    clickToScrollToBlock({ selector: '.js-scroll-to-description', scrollTo: '.js-description-section', offset: -50 ,root: next.container })
    clickToScrollToBlock({ selector: '.js-scroll-to-technical-sheet', scrollTo: '.js-technical-sheet-section', offset: -50 ,root: next.container })
    clickToScrollToBlock({ selector: '.js-scroll-to-installation', scrollTo: '.js-installation-section', offset: 0 ,root: next.container })
    clickToScrollToBlock({ selector: '.js-scroll-to-buy-online', scrollTo: '.js-buy-online-section', offset: -50 ,root: next.container })
    select()
    selectProductOnClick()
    slickSimilarProducts(next.container)
  }
  
  static leaveProduct(current) {
    this.leave(current)
    destroySlick(current.container)
  }
}

// Initialisation sur la page de liste de nouvelles
export class News extends View {
  static initNews(next) {
    this.init(next)
    select()
  }
  
  static leaveNews(current) {
    this.leave(current)
  }
}

// Initialisation sur la page de détail de nouvelle
export class NewsDetails extends View {
  static initNewsDetails(next) {
    this.init(next)
    slickProduct(next.container)
    slickSimilarProducts(next.container)
  }
  
  static leaveNewsDetails(current) {
    this.leave(current)
    destroySlick(current.container)
  }
}

// Initialisation sur la page de contact
export class Contact extends View {
  static initContact(next) {
    this.init(next)
    textareaHeight()
    formContact()
    select()
    clickToScrollToBlock({ selector: '.js-scroll-to-contact-form', scrollTo: '.js-contact-form-section', offset: 0 ,root: next.container })
  }
  
  static leaveContact(current) {
    this.leave(current)
  }
}

// Initialisation sur la page à propos
export class AboutUs extends View {
  static initAboutUs(next) {
    this.init(next)
  }
  
  static leaveAboutUs(current) {
    this.leave(current)
  }
}

// Initialisation sur la page vente en ligne
export class OnlineSales extends View {
  static initOnlineSales(next) {
    this.init(next)

    new Accordions()
  }
  
  static leaveOnlineSales(current) {
    this.leave(current)
  }
}

// Initialisation sur la page carrière - Liste
export class JobsList extends View {
  static initJobsList(next) {
    this.init(next)
    formEmploi()
    select()
    fileUpload(next.container)
  }
  
  static leaveJobsList(current) {
    this.leave(current)
  }
}

// Initialisation sur la page carrière - Détail
export class JobsDetail extends View {
  static initJobsDetail(next) {
    this.init(next)
    formEmploi()
    fileUpload(next.container)
    slickSimilarProducts(next.container)
    new Accordions()
  }
  
  static leaveJobsDetail(current) {
    this.leave(current)
    destroySlick(current.container)
  }
}

// Initialisation sur la page de recherche
export class Search extends View {
  static initSearch(next) {
    this.init(next)
    select()
    clearInput()
    formSearch('#search-form-page','#submit-search-page')
  }
  
  static leaveSearch(current) {
    this.leave(current)
  }
}