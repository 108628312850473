/*
|--------------------------------------------------------------------------
| Transition avec barba et gestion des vues
|--------------------------------------------------------------------------
|
| C'est ici que les transitions entre les pages sont gérées, on y retrouve
| les différentes vues spécifiques à chaque page. Les vues sont également
| définies ici.
*/

import barba from '@barba/core'
import { defaultTransition } from './transitions/default-transition.js'
import { View, Generic, Home, Products, ProductsCategory, Product, News, NewsDetails, Contact, AboutUs, OnlineSales, JobsList, JobsDetail, Search } from './global.js'

window.barba = barba

export const initBarba = () => {

  const duration = 500

  barba.hooks.enter(({ current }) => { current.container.remove() })
  barba.hooks.before(() => { barba.wrapper.classList.add('show-transition')    })
  barba.hooks.after (() => {
    barba.wrapper.classList.remove('show-transition')
    if( typeof gtag === 'function' ) gtag('event', 'page_view', { 'send_to': 'UA-99140353-1', 'page_path': location.pathname })
    var _hsq = window._hsq = window._hsq || []
    _hsq.push(['setPath', location.pathname])
  })
  barba.init({
    sync: true,
    debug: true,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),
    transitions: [
      defaultTransition(duration),
    ],
    views: [
      {
        namespace: 'stdView',
        afterEnter({ next }) { View.init(next)  },
        afterLeave ({ current }) { View.leave(current) }
      },
      {
        namespace: 'genericView',
        afterEnter({ next }) { Generic.initGeneric(next)  },
        afterLeave ({ current }) { Generic.leaveGeneric(current) }
      },
      {
        namespace: 'homeView',
        afterEnter({ next }) { Home.initHome(next)  },
        afterLeave ({ current }) { Home.leaveHome(current) }
      },
      {
        namespace: 'productsView',
        afterEnter({ next }) { Products.initProducts(next)  },
        afterLeave ({ current }) { Products.leaveProducts(current) }
      },
      {
        namespace: 'productsCategoryView',
        afterEnter({ next }) { ProductsCategory.initProductsCategory(next)  },
        afterLeave ({ current }) { ProductsCategory.leaveProductsCategory(current) }
      },
      {
        namespace: 'productView',
        afterEnter({ next }) { Product.initProduct(next)  },
        afterLeave ({ current }) { Product.leaveProduct(current) }
      },
      {
        namespace: 'newsView',
        afterEnter({ next }) { News.initNews(next)  },
        afterLeave ({ current }) { News.leaveNews(current) }
      },
      {
        namespace: 'newsDetailsView',
        afterEnter({ next }) { NewsDetails.initNewsDetails(next)  },
        afterLeave ({ current }) { NewsDetails.leaveNewsDetails(current) }
      },
      {
        namespace: 'contactView',
        afterEnter({ next }) { Contact.initContact(next)  },
        afterLeave ({ current }) { Contact.leaveContact(current) }
      },
      {
        namespace: 'aboutUsView',
        afterEnter({ next }) { AboutUs.initAboutUs(next)  },
        afterLeave ({ current }) { AboutUs.leaveAboutUs(current) }
      },
      {
        namespace: 'onlineSalesView',
        afterEnter({ next }) { OnlineSales.initOnlineSales(next)  },
        afterLeave ({ current }) { OnlineSales.leaveOnlineSales(current) }
      },
      {
        namespace: 'jobsListView',
        afterEnter({ next }) { JobsList.initJobsList(next)  },
        afterLeave ({ current }) { JobsList.leaveJobsList(current) }
      },
      {
        namespace: 'jobsDetailView',
        afterEnter({ next }) { JobsDetail.initJobsDetail(next)  },
        afterLeave ({ current }) { JobsDetail.leaveJobsDetail(current) }
      },
      {
        namespace: 'SearchView',
        afterEnter({ next }) { Search.initSearch(next)  },
        afterLeave ({ current }) { Search.leaveSearch(current) }
      },
    ]
  })
}
